import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'ss101m',
    component: () => import('../views/SS101M/index.vue'),
    meta: {
      title: 'WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】',
      content: 'WEBで楽々！オンライン旅行相談。旅行目的や行き先に合わせてコンシェルジュがプランをご提案。旅行会社のカウンターで受けていた旅の説明を、ご自宅にいながら聞くことができます！まずはお気軽にお問い合わせを！'
    }
  },
  {
    path: '/ss102m',
    name: 'ss102m',
    component: () => import('../views/SS102M/index.vue'),
    meta: {
      title: 'WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/in101m',
    name: 'in101m',
    component: () => import('../views/IN101M/index.vue'),
    meta: {
      title: 'マイトップページ'
    }
  },
  {
    path: '/in102m',
    name: 'in102m',
    component: () => import('../views/IN102M/index.vue'),
    meta: {
      title: '予約一覧'
    }
  },
  {
    path: '/in103m',
    name: 'in103m',
    component: () => import('../views/IN103M/index.vue'),
    meta: {
      title: '予約詳細'
    }
  },
  {
    path: '/in104m',
    name: 'in104m',
    component: () => import('../views/IN104M/index.vue'),
    meta: {
      title: ' 会員情報の確認・変更'
    }
  },
  {
    path: '/ty001f',
    name: 'ty001f',
    component: () => import('../views/TY001F/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/ty002e',
    name: 'ty002e',
    component: () => import('../views/TY002E/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/pg0001',
    name: 'pg0001',
    component: () => import('../views/PG0001/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/pg0002',
    name: 'pg0002',
    component: () => import('../views/PG0002/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/pg0003',
    name: 'pg0003',
    component: () => import('../views/PG0003/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  },
  {
    path: '/pg0004',
    name: 'pg0004',
    component: () => import('../views/PG0004/index.vue'),
    meta: {
      title: ' WEBで楽々！オンライン旅行相談室！旅行のご相談は【グランシーノトリップ】'
    }
  }
]

export const asyncRoutes = [
  {
    path: '/rs101m',
    name: 'rs101m',
    component: () => import('@/views/RS101M/index.vue'),
    meta: {
      roles: ['Manager'],
      title: '予約'
    },
    children: [
      {
        path: 'rs102m',
        name: 'rs102m',
        component: () => import('@/views/RS102M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '旅程選択'
        }
      },
      {
        path: 'rs104m',
        component: () => import('@/views/RS104M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '交通手段選択'
        }
      },
      {
        path: 'rs105m',
        component: () => import('@/views/RS105M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: 'ホテル選択'
        }
      },
      {
        path: 'rs106m',
        component: () => import('@/views/RS106M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '旅程確認'
        }
      },
      {
        path: 'rs107m',
        component: () => import('@/views/RS107M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '支払い方法選択'
        }
      },
      {
        path: 'rs108m',
        component: () => import('@/views/RS108M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '最終確認'
        }
      },
      {
        path: 'rs109m',
        component: () => import('@/views/RS109M/index.vue'),
        meta: {
          roles: ['Manager'],
          title: '予約完了'
        }
      }
    ]
  },
  {
    path: '/rs110m',
    component: () => import('@/views/RS110M/index.vue'),
    meta: {
      roles: ['Manager'],
      title: '全体予約一覧'
    }
  },
  {
    path: '/rs111m',
    component: () => import('@/views/RS111M/index.vue'),
    meta: {
      roles: ['Manager'],
      title: '全体予約詳細'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '404'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
