const getters = {
  token: state => state.user.token,
  roles: state => state.user.roles,
  asyncRoutes: state => state.user.asyncRoutes,

  commission: state => state.rs101mSave.commission,
  checkAir: state => state.rs101mSave.checkAir,
  checkTrn: state => state.rs101mSave.checkTrn,
  checkHotel: state => state.rs101mSave.checkHotel,
  airList: state => state.rs101mSave.airList,
  trnList: state => state.rs101mSave.trnList,
  hotelForm: state => state.rs101mSave.hotelForm,
  hotelUrl: state => state.rs101mSave.hotelUrl,
  selectAirList: state => state.rs101mSave.selectAirList,
  selectTrnList: state => state.rs101mSave.selectTrnList,
  selectFareList: state => state.rs101mSave.selectFareList,
  hotelList: state => state.rs101mSave.hotelList,
  busList: state => state.rs101mSave.busList,
  carList: state => state.rs101mSave.carList,
  optionalList: state => state.rs101mSave.optionalList,
  selectHotelList: state => state.rs101mSave.selectHotelList,
  selectBusList: state => state.rs101mSave.selectBusList,
  selectCarList: state => state.rs101mSave.selectCarList,
  selectOptionalList: state => state.rs101mSave.selectOptionalList,

  loginUser: state => state.rs101mSave.loginUser,
  companionList: state => state.rs101mSave.companionList,
  selectCompanionList: state => state.rs101mSave.selectCompanionList,
  paymentType: state => state.rs101mSave.paymentType,
  rsv: state => state.rs101mSave.rsv

}
export default getters
