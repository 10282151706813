import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/permission' // permission control

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/index.scss'
import ja from 'element-plus/es/locale/lang/ja'
import * as ElIconModules from '@element-plus/icons'

import './utils/stringFormat'
import './utils/rem'
// import validate from './utils/validate'
import global from './utils/global'
import message from './utils/message'

import Emitter from 'tiny-emitter'

// https://lodash.com/docs/4.17.15
var lodash = require('lodash')

const emitter = new Emitter()
const app = createApp(App).use(store).use(router)

app.use(ElementPlus, {
  locale: ja
})

app.mount('#app')

// app.config.globalProperties.$validate = validate
app.config.globalProperties.GLOBAL = global

// elementUI-plus icon
for (const iconName in ElIconModules) {
  app.component(transElIconName(iconName), ElIconModules[iconName])
}
export function transElIconName(iconName) {
  return 'i' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase())
}

app.config.globalProperties.emitter = emitter
app.config.globalProperties.lodash = lodash
app.config.globalProperties.message = message
