// vue pxをremに変換
// ベースサイズ
const baseSize = 16

function setRem() {
  // 1920幅に対する現在のページ幅のスケーリング
  const scale = document.documentElement.clientWidth / 1080
  // フォントサイズを設定する
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初期化
setRem()
// ウィンドウのサイズが変更されたときに再設定
window.onresize = function() {
  setRem()
}
