const getDefaultState = () => {
  return {
    // 手数料
    commission: undefined,
    // 旅程選択画面
    checkAir: true,
    checkTrn: true,
    checkHotel: true,
    airList: [],
    trnList: [],
    hotelForm: [],
    hotelUrl: '',

    // 交通手段選択画面
    selectAirList: [],
    selectTrnList: [],
    // 運賃筛选
    selectFareList: [],

    // ホテル選択画面
    hotelList: [],
    busList: [],
    carList: [],
    optionalList: [],
    selectHotelList: [],
    selectBusList: [],
    selectCarList: [],
    selectOptionalList: [],

    // 登録者
    loginUser: {},
    // 同行者
    companionList: [],
    selectCompanionList: [],
    // お支払い方法
    paymentType: null,
    // 予約マスタ
    rsv: null
  }
}

const state = getDefaultState()

const mutations = {
  SAVE_COMMISSION: (state, commission) => {
    state.commission = commission
  },
  SAVE_CHECK_AIR: (state, checkAir) => {
    if (checkAir) {
      state.checkAir = checkAir
    } else {
      state.checkAir = false
    }
  },
  SAVE_CHECK_TRN: (state, checkTrn) => {
    if (checkTrn) {
      state.checkTrn = checkTrn
    } else {
      state.checkTrn = false
    }
  },
  SAVE_CHECK_HOTEL: (state, checkHotel) => {
    if (checkHotel) {
      state.checkHotel = checkHotel
    } else {
      state.checkHotel = false
    }
  },
  SAVE_AIR: (state, airList) => {
    state.airList = airList
  },
  SAVE_TRN: (state, trnList) => {
    state.trnList = trnList
  },
  SAVE_HOTEL: (state, hotelForm) => {
    state.hotelForm = hotelForm
  },
  SAVE_HOTEL_URL: (state, hotelUrl) => {
    state.hotelUrl = hotelUrl
  },
  SAVE_SELECT_AIR_LIST: (state, selectAirList) => {
    state.selectAirList = selectAirList
  },
  SAVE_SELECT_TRN_LIST: (state, selectTrnList) => {
    state.selectTrnList = selectTrnList
  },
  SAVE_SELECT_FARE_LIST: (state, selectFareList) => {
    state.selectFareList = selectFareList
  },

  SAVE_HOTEL_LIST: (state, hotelList) => {
    state.hotelList = hotelList
  },
  SAVE_BUS_LIST: (state, busList) => {
    state.busList = busList
  },
  SAVE_CAR_LIST: (state, carList) => {
    state.carList = carList
  },
  SAVE_OPTIONAL_LIST: (state, optionalList) => {
    state.optionalList = optionalList
  },
  SAVE_SELECT_HOTEL_LIST: (state, selectHotelList) => {
    state.selectHotelList = selectHotelList
  },
  SAVE_SELECT_BUS_LIST: (state, selectBusList) => {
    state.selectBusList = selectBusList
  },
  SAVE_SELECT_CAR_LIST: (state, selectCarList) => {
    state.selectCarList = selectCarList
  },
  SAVE_SELECT_OPTIONAL_LIST: (state, selectOptionalList) => {
    state.selectOptionalList = selectOptionalList
  },

  SAVE_SELECT_LOGINUSER: (state, loginUser) => {
    state.loginUser = loginUser
  },
  SAVE_COMPANION_LIST: (state, companionList) => {
    state.companionList = companionList
  },
  SAVE_SELECT_COMPANION_LIST: (state, selectCompanionList) => {
    state.selectCompanionList = selectCompanionList
  },
  SAVE_PAYMENT_TYPE: (state, paymentType) => {
    state.paymentType = paymentType
  },
  SAVE_RSV: (state, rsv) => {
    state.rsv = rsv
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

