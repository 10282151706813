<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss" >
  body{
    font-family:ArialMT,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3" !important;
  }
</style>
