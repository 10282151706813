import router from './router'
import { asyncRoutes } from '@/router'
import store from './store'
import NProgress from 'nprogress' // プログレスバープラグイン
import 'nprogress/nprogress.css' // プログレスバースタイル
import { getToken } from '@/utils/auth'
import { filterAsyncRoutes } from '@/utils/asyncRouter.js'

NProgress.configure({ showSpinner: false }) // プログレスバーの構成

const whiteList = ['/', '/ss102m', '/ty001f', '/ty002e', '/pg0001', '/pg0002', '/pg0003', '/pg0004']

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  // ルートメタでタイトルを取得し、ページタイトルに設定します
  document.title = to.meta.title
  // ユーザーのトークンを取得する
  const hasToken = getToken()
  // 現在のユーザーがログインしているかどうかを確認します
  if (hasToken) {
    if (to.path === '/ss102m') {
      // ユーザーロールを取得する
      const { roles } = await store.dispatch('user/getInfo')
      if (roles.includes('Manager')) {
        next({ path: '/rs101m/rs102m' })
      } else {
        next({ path: '/' })
      }
      NProgress.done()
    } else {
      // ストアからユーザーロールを取得する
      const hasRoutes = store.getters.asyncRoutes && store.getters.asyncRoutes.length > 0
      if (hasRoutes) {
        next()
      } else {
        try {
          // ユーザーロールを取得する
          const { roles } = await store.dispatch('user/getInfo')
          // ユーザーロールによって、ロールを取得する
          const accessRoutes = filterAsyncRoutes(asyncRoutes, roles)
          store.dispatch('user/setAsyncroutes', accessRoutes)
          // ルートをルーターに追加する
          accessRoutes.forEach(item => {
            router.addRoute(item)
          })
          next({ ...to, replace: true })
        } catch (error) {
          console.log(error)
          // ユーザーのログイン情報をクリアした後、ログインページに戻ります
          // next(`/ss102m?redirect=${to.path}`)
          next(`/ss102m`)
          NProgress.done()
        }
      }
    }
  } else {
    // ユーザーがログインしていません
    if (whiteList.indexOf(to.path) !== -1) {
      // ログインフリーホワイトリストで、直接next();
      next()
    } else {
      // そうでなければすべてログインページ
      // next(`/ss102m?redirect=${to.path}`)
      next(`/ss102m`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
