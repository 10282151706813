export default {
  put,
  get,
  clear
}

function put(tag, object) {
  localStorage.setItem(tag, JSON.stringify(object))
}

function get(tag) {
  return JSON.parse(localStorage.getItem(tag))
}

function clear() {
  localStorage.clear()
}
