import { createStore } from 'vuex'

import user from './modules/user'
import rs101mSave from './modules/rs101mSave'
import getters from './getters'

export default createStore({
  modules: {
    user,
    rs101mSave
  },
  getters
})
