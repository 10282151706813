
export default {
  E001: '該当するデータがありません',
  E002: '整数を入力ください',
  E003: '数字を入力ください',
  E004: '英数字を入力てください',
  E005: '日付を入力ください',
  E006: '電話番号を入力ください',
  E007: '正しい電話番号を入力てください',
  E008: 'メールアドレスを入力てください',
  E009: 'ユーザー名を入力してください',
  E010: 'メールを入力してください',
  E011: '会員氏名を入力してください',
  E012: '会員カナを入力してください',
  E013: '性別区分を選択してください',
  E014: '日程を選択してください',
  E015: '出発地を選択してください',
  E016: '到着地を選択してください',
  E017: '大人人数を選択してください',
  E018: '出発地・到着地のどちらかを変更してください',
  E019: '出発日時を入力してください',
  E020: '宿泊日を選択してください',
  E021: '宿泊地を選択してください',
  E022: '最大文字数:{0}',
  E023: 'ホテル名を入力してください',
  E024: 'チェックインを入力してください',
  E025: 'チェックアウトを入力してください',
  E026: '部屋数を入力してください',
  E027: '宿泊人数(大人)を入力してください',
  E028: '合計料金を入力してください',
  E029: 'YYYY/MM/DD形で入力してください',
  E030: '日付は正しくありません',
  E031: 'バス会社を入力してください',
  E032: '便名を入力してください',
  E033: '乗車地を入力してください',
  E034: '降車地を入力してください',
  E035: '出発時刻を入力してください',
  E036: '到着時刻を入力してください',
  E037: '人数(大人)を入力してください',
  E038: 'YYYY/MM/DD HH:mm形で入力してください',
  E039: 'レンタカー会社を入力してください',
  E040: 'プランを入力してください',
  E041: '貸出場所を入力してください',
  E042: '返却場所を入力してください',
  E043: '貸出日時を入力してください',
  E044: '返却日時を入力してください',
  E045: '数量を入力してください',
  E046: '催行会社を入力してください',
  E047: 'コース名を入力してください',
  E048: '利用日を入力してください',
  E049: '人数を入力してください',
  E050: '規約に同意して進んでください',
  E051: '「ログイン」または「登録」してください',
  E052: '同行者名を入力してください',
  E053: '同行者カナを入力してください',
  E054: '生年月日を入力してください',
  E055: 'お支払い方法を選択してください',
  E056: '何も予約されていません',
  E057: 'キャンセル操作が失敗しました',
  E058: '手配完了操作が失敗しました',
  E059: '入金が失敗しました',
  E060: '発券が失敗しました',
  E061: 'メモの更新が失敗しました',
  E062: 'チケットURLの更新が失敗しました',
  E063: 'チケットファイルの更新が失敗しました',
  E064: '更新が失敗しました',
  E065: '現在のパスワードを入力ください',
  E066: 'パスワードを入力ください',
  E067: 'パスワードは6文字以上20文字以下で入力してください',
  E068: '上のパスワード欄と同様のものを入力してください',
  E069: '生年月日を入力してください',
  E070: '期限切れキャンセル操作が失敗しました',
  E071: '有效な期間を選択してください',
  E072: '全角カナで入力してください',
  E073: 'アップロードが失敗しました',

  T001: '予約希望日時を入力してください',
  T002: 'お名前カタカナを入力してください',
  T003: '出発地を入力してください',
  T004: 'ご希望方面を入力してください',
  T005: 'ご出発日・ご旅行期間を入力してください',
  T006: '人数を入力してください',
  T007: '交通手段を入力してください',

  I001: '該当するデータがありません',
  I002: '入力していただいた登録メールアドレスに「仮パスワード」をメールで送信いたします',
  I003: '検索結果はありません',
  I004: 'メモが更新しました',
  I005: 'キャンセル操作が成功しました',
  I006: '手配完了操作が成功しました',
  I007: '入金が成功しました',
  I008: '発券が成功しました',
  I009: '更新しました',
  I010: 'チケットURLが更新しました',
  I011: 'チケットファイルが更新しました',
  I012: '「手配完了・入金依頼」メールは送信しました',
  I013: '「リクエスト」メールは送信しました',
  I014: '「入金完了・正式予約」メールは送信しました',
  I015: '「期限切れキャンセル」メールは送信しました',
  I016: '会員規約に同意してください',
  I017: '更新しました',
  I018: '期限切れキャンセル操作が成功しました'

}
